.like__avatar {
  margin-left: 12px !important;
}
.like__avatar .MuiAvatar-root {
  width: 26px !important;
  height: 26px !important;
  background-color: #785ea8 !important;
  font-size: 12px !important;
}

.likes__popup {
  position: absolute;
  left: 0;
  top: 65px;
  background: #fff;
  padding: 10px;
  z-index: 10;
  box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
  border-radius: 8px;
}
.likes__popup div {
  white-space: nowrap;
  font-size: 10px;
  margin: 8px 0;
}

.likes__container-lastChild {
  border: none !important;
}

.likes__container {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  padding: 14px;
  border-bottom: 0.5px solid rgba(112, 112, 112, 0.35);
}

.post__likes {
  margin-top: 2rem;
  max-height: 500px;
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.post__likes::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.like__image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.commented_name {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.comment_content,
.coment_and_like_timing {
  font-size: 16px !important;
  font-weight: 300 !important;
}
.coment_and_like span {
  font-size: 16px !important;
}
.card_content_comment_content_container {
  user-select: none;
}

.card_content_comment_content_container {
  flex-wrap: wrap;
}
.card_content_comment_empry_comment_image {
  min-width: 58px;
  min-height: 58px;
}

.sub_comment_container_image_container {
  min-width: 40px;
  min-height: 40px;
  border-radius: 8px;
}
.card_content_comment_empry_comment_container {
  margin-bottom: 1rem !important;
}
.sub_comment_container {
  margin-bottom: 16px !important;
  margin-top: -10px !important;
  align-items: start !important;
}
