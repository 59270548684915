@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/*!
 * sob design system
 * Copyright 2021 The sob Authors
 * Licensed under MIT
 */
:root {
  --sob-primary: #785ea8 !important;
  --sob-primary-text-color: #fff;
  --sob-primary-hover-color: #644798 !important;
  --sob-primary-disabled-color: #ebe9f0 !important;
  --sob-primary-rgb: 0, 172, 210 !important;

  --sob-secondary: rgba(120, 94, 168, 0.08) !important;
  --sob-secondary-text-color: #785ea8 !important;
  --sob-secondary-hover-color: #5a6268;
  --sob-secondary-disabled-color: #b0b6ba;

  --sob-success: #28a745;
  --sob-success-text-color: #fff;
  --sob-success-hover-color: #218838;
  --sob-success-disabled-color: #74c686;

  --sob-info: #17a2b8;
  --sob-info-text-color: #fff;
  --sob-info-hover-color: #138496;
  --sob-info-disabled-color: #67c3d0;

  --sob-warning: #ffc107;
  --sob-warning-text-color: #fff;
  --sob-warning-hover-color: #e0a800;
  --sob-warning-disabled-color: #ffd75e;

  --sob-danger: #dc3545;
  --sob-danger-text-color: #fff;
  --sob-danger-hover-color: #c82333;
  --sob-danger-disabled-color: #e97b86;

  --sob-light: #f8f9fa;
  --sob-light-text-color: #343a40;
  --sob-light-hover-color: #e2e6ea;
  --sob-light-disabled-color: #fafcfc;

  --sob-dark: #343a40;
  --sob-dark-text-color: #fff;
  --sob-dark-hover-color: #23272b;
  --sob-dark-disabled-color: #7a7e83;

  --sob-link-color: #0d6efd;
  --sob-link-hover-color: #0a58ca;
  --sob-link-disabled-color: #6c757d;

  --sob-blue: #785ea8 !important;
  --sob-blue-text-color: #fff;
  --sob-blue-hover-color: #644798 !important;
  --sob-blue-disabled-color: #ebe9f0 !important;

  --sob-gray: #6c757d;
  --sob-gray-text-color: #fff;
  --sob-gray-hover-color: #5a6268;
  --sob-gray-disabled-color: #b0b6ba;

  --sob-statisticalCard-warning: rgba(249, 170, 0, 0.08);
  --sob-statisticalCard-warning-text-color: #eaa207;

  --sob-statisticalCard-info: rgba(85, 172, 237, 0.08);
  --sob-statisticalCard-info-text-color: #55aced;

  --sob-statisticalCard-primary: #efedf3;
  --sob-statisticalCard-primary-text-color: #02829f;

  --sob-cyan: #0dcaf0;
  --sob-indigo: #6610f2;
  --sob-purple: #6f42c1;
  --sob-pink: #d63384;
  --sob-red: #dc3545;
  --sob-orange: #fd7e14;
  --sob-yellow: #ffc107;
  --sob-green: #198754;
  --sob-teal: #20c997;
  --sob-white: #fff;
  --sob-gray-dark: #343a40;
  /* --sob-primary: #0d6efd; */
  --sob-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --sob-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --sob-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --sob-blue-box-shadow: rgba(49, 132, 253, 0.5);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: Poppins;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Poppins;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: Poppins;
}
