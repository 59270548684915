.PostCardLoading .card {
  margin: 0 !important;
  margin-bottom: 30px !important;
  overflow: hidden;
  width: 100%;
  max-width: inherit !important;
}

.PostCardLoading .img-container {
  width: calc(100% + 16px + 16px);
  min-height: 290px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

.PostCardLoading .img {
  height: 70px;
  width: 73.6px;
}

.AllergiesCard {
  max-width: 356.7px;
  min-width: 318.7px;
  border: solid 1px #efefef;
  padding: 17.7px 13.7px 52px 18px;
  height: 329px;
  border-radius: 10px;
  position: relative;
  margin: 15px;
}

.LoadingMeasurementDisplayField {
  height: 42.19px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoadingMeasurementDisplayFields-container {
  display: flex;
  align-items: center;
}

.TimeLineLoadingItem__container {
  padding: 1rem 1.1rem;
  border-radius: 6px;
  background-color: rgb(245, 250, 252);
  margin-bottom: 25px;
  min-height: 293px;
}

.TimeLineLoadingItem__container:nth-child(2n) {
  background-color: rgb(246, 255, 244);
}
