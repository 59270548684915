::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #785ea8 !important;
  // border-radius: 4px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(120, 94, 168) !important;
  border-radius: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #785ea8 !important;
}

.workspace__dashboard {
  zoom: 100%;
}

.navbar-user-name {
  white-space: nowrap !important;
}

.sob-input-select__menu {
  ::-webkit-scrollbar {
    width: 4px !important;
    // display: none;
  }
}
.sob-input-select__control {
  ::-webkit-scrollbar {
    width: 4px !important;
    // display: none;
  }
}

.workspace__dashboard {
  font-family: Poppins;
  // overflow-x: hidden;
  min-height: 100vh;
  scroll-behavior: smooth !important;
  background-color: rgba(120, 94, 168, 0.06) !important;
  padding-bottom: 2rem !important;

  #roleaddorupdate .react-ip-input {
    padding: 0.5rem 0.875rem !important;
    color: gray !important;
    font-size: 0.813rem !important;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #c6c6c6;
    border-radius: 0.5rem;
    color: #000;
    display: block;
    font-family: Poppins;
    font-size: 0.813rem;
    font-weight: 300;
    height: 55px;
    line-height: 1.5;
    max-width: 1144px;
    min-width: 200px;
    outline: none;
    padding: 0.5rem 1rem;
    text-align: left;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sob-multi-image-picker .card-image-action-delete {
    display: flex;
  }
  #roleaddorupdate .react-ip-input ipput {
    width: 80px;
  }

  // .breadcrumb {
  //   padding: 1rem 0 !important;
  //   margin: 0 !important;
  // }

  .sob-form-control,
  .basic-select .basic-select__input > input {
    caret-color: #785ea8 !important;
  }

  .sob-btn-custom {
    width: 100% !important;
    padding-right: 70px !important;
    margin-bottom: 14px !important;
  }

  .CustomButton__sob-text {
    max-width: none !important;
  }

  .tabs__actions {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .dropdown-item-link {
    text-decoration: none;
  }

  .table__container {
    flex: 1;
    border-radius: 1rem !important;
    background-color: #fff !important;
    padding: 1rem !important;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
  }

  .table__notintabs {
    border-radius: 1rem !important;
    background-color: #fff !important;
    padding: 13px 30px !important;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
    margin: 0 !important;
  }

  .table__view__container {
    border-radius: 1rem !important;
    background-color: #fff !important;
    padding: 13px 30px !important;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
  }

  .MuiPaper-elevation1 {
    border-radius: 1rem !important;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
  }

  .inTabs .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  // th {
  //   text-transform: inherit !important;
  // }

  // .table th {
  //   white-space: nowrap !important;
  // }

  .table td {
    white-space: nowrap !important;
  }

  .galerie__container .table td {
    max-width: 600px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .event__history .table td {
    white-space: break-spaces !important;
  }

  .table tr {
    cursor: pointer !important;
  }

  // SEARCH BUTTON/
  .sob-table-search-submit {
    background-color: rgb(120, 94, 168) !important;
  }

  .sob-form-control {
    padding: 0.5rem 0.875rem !important;
    color: hsl(0, 0%, 50%) !important;
    font-size: 0.813rem !important;
  }

  .sob-form-control::placeholder {
    font-size: 0.813rem !important;
  }

  .navbar-user-name {
    white-space: nowrap !important;
  }

  .view .sob-table-header {
    padding: 0 !important;
  }

  /*
.sob-modal {
  bottom: inherit !important;
} */

  .rug .rug-items.__card {
    grid-gap: 60px !important;
  }

  .rug-item .sob-form-control {
    display: none !important;
  }

  /* gg */
  body .basic-select .basic-select__control {
    padding: 0 !important;
  }

  .HomePage_contianer {
    margin: 0 1.5% !important;
  }

  .nav-link:hover {
    cursor: pointer !important;
  }

  .sob-card {
    // margin: 0 !important;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
  }

  .sob-audit-card {
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%) !important;
  }

  .cart_title_header_container {
    align-items: flex-end !important;
  }

  .cart_title_header_container_button {
    background-color: #b9b9b915 !important;
  }

  .cart_title {
    font-family: inherit !important;
    margin-left: 1rem !important;
  }

  .cart_title_client_name {
    font-weight: bolder !important;
    margin-left: 0.3rem !important;
  }

  .wrapperClassName {
    background: #fff !important;
    border: 1px solid #c6c6c6 !important;
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  .toolbarClassName {
    border-bottom: 1px solid #c6c6c6 !important;
    border-top: 0px !important;
    border-right: 0px !important;
    border-left: 0px !important;
    background-color: #fdfdfd !important;
  }
  .rdw-dropdown-optionwrapper,
  .rdw-dropdown-wrapper {
    ::-webkit-scrollbar {
      width: 4px !important;
      // display: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(120, 94, 168) !important;
      border-radius: 4px !important;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #785ea8 !important;
    }
  }

  .Button_outline:hover {
    background-color: #785ea8 !important;
    color: #fff !important;
  }

  .dropzone {
    background-color: #fff !important;
    border: 1px solid rgb(187, 200, 211) !important;
    padding: 3% 10% !important;
    border-radius: 4px !important;
    color: rgb(120, 94, 168) !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
  }

  .sob-accordion__panel_item.active {
    background-color: rgba(120, 94, 168, 0.08) !important;
    color: rgb(120, 94, 168) !important;
    font-weight: 600 !important;
  }

  .breadcrumb-item,
  .breadcrumb-item:hover,
  a:hover {
    color: rgb(120, 94, 168) !important;
  }

  .sobrus_desc_container {
    background-color: rgba(120, 94, 168, 0.23) !important;
    margin-top: 7px !important;
    padding: 10px !important;
    border: 0.5px dotted rgb(120, 94, 168) !important;
    border-radius: 5px !important;
    margin-bottom: 5px !important;
    list-style: none !important;
  }

  .sobrus_desc_container ul {
    list-style: none !important;
  }

  .react-ip-input {
    width: max-content !important;
  }

  .SettingsTransactionsKilometer_paragraphe {
    font-weight: 600 !important;
    color: rgb(120, 94, 168) !important;
    font-size: 12px !important;
  }

  .modification_CSV_dropzone {
    align-items: center !important;
    border-style: dashed !important;
    border-width: 2px !important;
    border-radius: 20px !important;
    border-color: rgb(204, 204, 204) !important;
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    justify-content: center !important;
    padding: 20px !important;
    cursor: pointer !important;
  }

  .card_header_date {
    font-weight: 300 !important;
  }

  .sob-breadcrumb-singletitle .breadcrumb-title:after {
    width: 0 !important;
  }

  .editorClassName {
    min-height: 200px !important;
    padding: 8px !important;
  }

  .description__editor .toolbarClassName {
    display: none !important;
  }

  .toolbarClassName {
    justify-content: center !important;
    border-radius: 8px !important;
    box-shadow: inset 0px 0px 20px 12px rgba(120, 94, 168, 75%) !important;
  }

  .wrapperClassName {
    padding: 8px !important;
  }

  .rdw-option-active {
    background-color: #785ea8 !important;
    color: #fafafa !important;
  }

  .TooltipMenu__actions-trigger-btn svg {
    color: #785ea8 !important;
  }

  #form_add_post .sob-form-group {
    flex: 1 !important;
  }

  #form_add_post .sob-form-group .sob-form-control {
    width: -webkit-fill-available !important;
  }

  .form__avatar div {
    border-radius: 50% !important;
  }

  .card_content_image_container {
    max-height: 100% !important;
    border-radius: 8px !important;
  }

  .card_content_image_container img {
    border-radius: 8px !important;
  }

  .infinite-scroll-component {
    overflow: visible !important;
  }

  /* #my-form .css-2613qy-menu {
  display: block !important ;
  z-index: 100000000 !important ;
} */
  .sob-modal {
    overflow: visible !important;
  }

  .sob-droppable-container {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }

  .sob-droppable-container::-webkit-scrollbar {
    display: none !important;
  }

  .sob-droppable-container {
    -ms-overflow-style: none !important;
    overflow: -moz-scrollbars-none !important;
  }

  .sob-droppable-title-container {
    background-color: rgba(120, 94, 168, 100%) !important;
  }

  .sob-droppable-title-container svg,
  .sob-droppable-title {
    color: #fafafa !important;
  }

  .sob-modal-content {
    border-radius: 12px !important;
  }

  .check_in_container .sob-image-picker-header {
    min-width: auto !important;
  }

  .sob-image-picker .rug-card {
    min-width: 250px !important;
    width: auto !important;
  }

  /* .sob-btn-outline-primary:hover {
  background-color: rgb(120, 94, 168) !important;
} */

  .TableHeader__btn {
    background-color: rgba(120, 94, 168, 0.1) !important;
    color: #785ea8 !important;
  }

  .sob-audit-card-body {
    padding: 1rem 0 !important;
  }

  .sob-form-control:focus {
    border-color: #785ea8;
  }

  .basic-select .basic-select__control--is-focused {
    border: solid 1px #785ea8 !important;
  }

  .breadcrumb-item:hover {
    color: #785ea8;
  }

  // .breadcrumb-item,
  // a:hover {
  //   color: #785ea8;
  // }

  .sob-date-picker-primary .react-datepicker__current-month {
    color: #785ea8 !important;
  }

  .sob-empty-data-icon-container {
    background-color: rgba(120, 94, 168, 0.1) !important;
  }

  // .sob-icon-btn-primary {
  //   background-color: rgba(120, 94, 168, 0.10) !important
  // }

  .sob-card-item-title {
    font-weight: 300 !important;
  }

  .sob-card-item-value {
    font-weight: 500 !important;
  }

  .contract .table td {
    max-width: 200px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  .checkbox input:checked {
    --b: #785ea8 !important;
  }

  .basic-select .basic-select__placeholder,
  .basic-select .basic-select__single-value {
    font-size: 0.813rem !important;
  }

  /* use for any tag on your page */
  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  /* box for title text */
  [data-title]:after {
    content: attr(data-title);
    /* position for title view box */
    position: absolute;
    bottom: -1em;
    right: 50%;
    z-index: 99999;
    visibility: hidden;
    /* optional */
    /* stilization */
    white-space: nowrap;
    background-color: rgba(120, 94, 168, 0.8);
    color: #fff;
    font-size: 11px;
    padding: 8px;
    box-shadow: 1px 1px 3px #fff;
    opacity: 0;
    border: 1px solid #785ea8;
    border-radius: 8px;
  }

  [data-title] {
    position: relative;
  }

  .sob-icon-btn.disabled {
    display: none !important;
  }

  .sob-card-body-label {
    text-transform: uppercase !important;
  }

  .react-ip-input input {
    font-size: 14px !important;
  }

  .CustomButton__sob-primary .CustomButton__sob-arrow-container,
  .CustomButton__sob-primary .CustomButton__sob-icon-container {
    background-color: rgba(0, 177, 216, 0.08) !important;
  }
}

// .popup-content {
//   left: auto !important;
//   right: 38px !important;
// }

@media only screen and (max-width: 900px) {
  #root .navbar {
    display: none !important;
  }
}

@media only screen and (max-width: 1100px) {
  .EventAddUpdateModal .card {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .workspace__dashboard,
  .navbar,
  .EventAddUpdateModal {
    zoom: 85%;
  }

  .popup-content {
    // right: 28px !important;
    zoom: 85%;
  }
}

// .rdw-dropdown-wrapper {
//   z-index: 0 !important;
// }

.sob-invalid-feedback {
  margin-left: 4px !important;
  display: block !important;
}

textarea {
  resize: none;
}

// .sob-form-control {
//   height: 55px !important;
//   padding: 0.5rem 1rem !important;
// }
/* // Modal  */
.EventAddUpdateModal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.EventAddUpdateModal .card {
  width: 40%;
  min-height: auto;
  // height: fit-content;
  position: relative;
  padding: 16px;
}

.card_header_date_container button {
  width: auto !important;
  height: auto !important;
}

.sob-form-control {
  max-width: none !important;
}

.card_content_image_container img {
  // width: auto !important;
  object-fit: contain !important;
  display: flex !important;
  justify-content: center !important;
  margin: 0 auto !important;
  max-width: 100% !important;
}

.MuiLinearProgress-root {
  height: 2px !important;
  padding: 0 16px !important;
  /* margin-bottom:-2px !important; */
}

.dropdown-menu {
  list-style: none !important;
  padding: 0.6rem 1rem !important;
  margin-top: 30px !important;
  cursor: pointer !important;
}

.workspace__sideMenuItem {
  display: flex;
  align-items: center;
}

[data-name]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

/* box for name text */
[data-name]:after {
  content: attr(data-name);
  /* position for name view box */
  position: absolute;
  top: -0.5rem;
  left: 10%;
  max-width: 100px;
  height: auto;
  z-index: 99999;
  visibility: hidden;
  /* optional */
  white-space: nowrap;
  /* stilization */
  background-color: rgba(120, 94, 168);
  color: #fff;
  font-size: 11px;
  padding: 8px;
  box-shadow: 1px 1px 3px #fff;
  opacity: 0;
  border: 1px solid #fff;
  border-radius: 8px;
}

.MuiAvatar-root {
  position: inherit !important;
}

.sob-input-select .sob-input-select__placeholder,
.sob-input-select .sob-input-select__single-value {
  white-space: nowrap !important;
}

.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__year-dropdown-container--select {
  margin: 12px 12px 0 !important;
}

.react-datepicker__month-read-view--down-arrow {
  margin-top: 2px !important;
}

.Calendar__TooltipEvent-avatar-container img {
  height: 30px !important;
  width: 30px !important;
  border-radius: 8px !important;
}

.Calendar__TooltipEvent-collaborater {
  display: flex;
  align-items: center;
}

.Calendar__TooltipEvent-header {
  grid-gap: 20px;
}
