.breadcrumb {
  margin: 0 !important;
  min-height: 79px !important;
}
.breadcrumb .breadcrumb__title {
  color: #000;
  font-family: Poppins;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.46;
  margin: 0;
  padding-right: 1.063rem;
  /* position: relative; */
}
.breadcrumb .breadcrumb__subtitle {
  color: #000;
  font-family: Poppins;
  font-size: 0.825rem;
  font-weight: 400;
  line-height: 1.46;
  margin: 0;
  padding-right: 1.063rem;
  /* position: relative; */
}
