.sobrus-login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #fafafa 50%, #785ea8 50%);
}
.sobrus-login__button {
  width: 20%;
  min-width: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  border-radius: 8px;
}
.sobrus-login__button img {
  width: 15%;
  min-width: 300px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  border-radius: 8px;
}
.sobrus-login__button p {
  color: #785ea8;
}
